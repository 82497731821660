<template>
  <page-container class="users-table" :title="$t('pages.titles.users')">
    <div class="block-subtitle mt-3">Список организаций, зарегистрированных в системе</div>

    <v-sheet class="mt-3 pa-8">
      <v-data-table
        class="v-data-table_clickable-rows"
        :headers="tableConfiguration"
        :items="users"
        :options.sync="tableSettings"
        :footer-props="{ itemsPerPageOptions: [15, 30, 50, 100] }"
        :loading="isPending"
        multi-sort
        :server-items-length="usersCount"
        @click:row="handleUserClick"
      >
        <template #item.account_type="{ item }">
          {{ $t(`users.accountType.${item.account_type}`) }}
        </template>
      </v-data-table>
    </v-sheet>
  </page-container>
</template>

<script>
  import _ from 'lodash'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { tableSettingsToRequestParameters } from '@/utils/tables'

  const tableConfiguration = [
    { text: 'Полное наименование', value: 'full_name' },
    { text: 'Почта', value: 'login', width: 300 },
    { text: 'Право доступа', value: 'account_type', width: 200 }
  ]

  const defaultTableSettings = {
    page: 1,
    itemsPerPage: 15,
    multiSort: true,
    sortBy: ['login'],
    sortDesc: [false]
  }

  export default {
    name: 'UsersTableView',
    mixins: [PendingTasksManagerMixin],

    data() {
      return {
        tableConfiguration,
        tableSettings: _.cloneDeep(defaultTableSettings),

        users: [],
        usersCount: 0
      }
    },

    watch: {
      tableSettings: 'fetchUsers'
    },

    methods: {
      @handlePendingTask()
      async fetchUsers() {
        const requestData = tableSettingsToRequestParameters(this.tableSettings)
        const result = await this.$api.backend.users.get_users(requestData)
        this.users = result.items
        this.usersCount = result.count
      },

      handleUserClick(user) {
        this.$router.push(`/user/profile/${user.id}`)
      }
    }
  }
</script>

<!--<style lang="scss" scoped></style>-->
